<template>
  <div>
    <h4 class="page-header"> Beedee Agent Commission</h4>
    <div class="panel panel-inverse">
      <div class="panel-body">
        <div class="d-flex flex-row">
          <div class="form-group mr-2">
            <label>Start</label>
            <date-picker
                v-model="start_date"
                format="yyyy-MM-dd"
                input-class="form-control bg-white"
            />
          </div>
          <div class="form-group">
            <label>End</label>
            <date-picker
                v-model="end_date"
                format="yyyy-MM-dd"
                input-class="form-control bg-white"
            />
          </div>
        </div>
        <vue-good-table :columns="columns" :lineNumbers="true"
                        :pagination-options="{ enabled: true, position: 'bottom' }" :rows="bids"
                        :search-options="{ enabled: true }">
          <div slot="table-actions">
            <download-excel v-if="bids.length"
                            :data="bids"
                            :export-fields="{'Total Commission':'total_commission','Monthly Commission':'monthly_commission','Agent' : 'agent_name'}"
                            :title="'Beedee Agent Commission'" class="btn btn-primary" name="Agent Commission.xls">
              <i class="fa fa-file-excel"></i> Export Excel
            </download-excel>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>


<script>
import DatePicker from 'vuejs-datepicker';
import moment from 'moment/src/moment';
import helper from "@/mixins/helper";

export default {
  mixins: [helper],
  components: {
    DatePicker
  },
  data() {
    return {
      columns: [
        {
          label: 'Total Commission',
          field: 'total_commission',
          formatFn: this.formatFn
        },
        {
          label: 'Monthly Commission',
          field: 'monthly_commission',
          formatFn: this.formatFn
        },
        {
          label: 'Agent',
          field: 'agent_name'
        }
      ],
      start_date: moment().add('week', '-1').toDate(),
      end_date: moment().toDate(),
      bids: []
    }
  },
  watch: {
    start_date() {
      this.fetch();
    },
    end_date() {
      this.fetch();
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.is_loaded = false;
      this.$axios.get(`/api/v1/reports/commission/beedee-agents?start_date=${moment(this.start_date).format('YYYY-MM-DD')}&end_date=${moment(this.end_date).format('YYYY-MM-DD')}`).then((resp) => {
        this.is_loaded = true;
        this.bids = resp.data;
      });
    },
  }
}
</script>